import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import axios from "../api/axios";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const IdVerification = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${user?.accessToken}`,
    },
  };

  useEffect(() => {
    if (!user) {
      navigate("/login", {
        state: {
          redirectTo: "/identity-verification"
        }
      });
      return;
    }
  }, [user]);

  const createSession = async () => {
    try {
      if (user.isStripeVerified) {
        toast.success("Already verified");
        return;
      }
      if (!user.isStripeVerified) {
        const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
        const stripePromise = loadStripe(key);
  
        const response = await axios.post(`api/verification/create-verification-session`, {
          userId: user.stripeCustomerId,
        }, config);
        const client_secret = response.data.client_secret;
  
        const stripe = await stripePromise;
        const result = await stripe.verifyIdentity(client_secret);
        if (result.error) {
          throw result;
        } 
        toast.success("Verification complete");

      }
    } catch (e) {
      console.log(e);
      toast.error("Verification failed");
    }
  };

  if (user?.isStripeVerified) {
    return (
      <section>
        <Container>
          <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Identity Verification</h1>
            <p className="lead">Your identity is already verified.</p>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="card-deck mb-3 text-center d-flex align-items-center gap-4">
              <button
                className="btn btn-lg btn-block btn-primary"
                id="checkout-and-portal-button"
                onClick={() => navigate("/")}
              >
                Go Back
              </button>
            </div>
          </div>
        </Container>
      </section>
    );
  }

  return (
    <section>
      <Container>
        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 className="display-4">Identity Verification</h1>
          <p className="lead">Please verify your identity by clicking on the button below.</p>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="card-deck mb-3 text-center d-flex align-items-center gap-4">
            <button
              className="btn btn-lg btn-block btn-primary"
              id="checkout-and-portal-button"
              onClick={createSession}
            >
              Start Verification
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default IdVerification;
